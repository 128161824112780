// Buttons:
$bmd-btn-font-size: .875rem !default; // 14px
$bmd-btn-font-size-lg: 1.25rem !default;
$bmd-btn-font-size-sm: .6875rem !default; // 11px
$bmd-btn-margin-bottom: .3125rem !default; // 5px

// default btn with no specific type designation
$bmd-btn-color: $gray-dark !default;
$bmd-btn-bg: transparent !default; //$body-bg !default; // #fff
$bmd-btn-border: #ccc !default;

$bmd-btn-focus-bg: rgba(#999, .20) !default; // spec: bg Hover: 20% #999999
$bmd-btn-active-bg: rgba(#999, .40) !default; // spec: bg Pressed: 40% #999999
$bmd-btn-disabled: rgba($black, .26) !default; // spec: light theme: Disabled text: 26% $black

$bmd-inverse-btn-focus-bg: rgba(#ccc, .15) !default; // spec: dark bg Hover: 15% #CCCCCC
$bmd-inverse-btn-active-bg: rgba(#ccc, .25) !default; // spec: dark Pressed: 25% #CCCCCC
$bmd-inverse-btn-disabled: rgba($white, .30) !default; // spec: dark theme: Disabled text: 30% $white

$bmd-btn-fab-size: 3.5rem !default; // 56px
$bmd-btn-fab-size-sm: 2.5rem !default; // 40px
$bmd-btn-fab-font-size: 1.5rem !default; // 24px

// icons
$bmd-btn-icon-size: 2rem !default; // 32px
$bmd-btn-icon-size-sm: (.75 * $bmd-btn-icon-size) !default; // ~24px
$bmd-btn-icon-font-size-sm: (.75 * $bmd-btn-fab-font-size) !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.
$input-btn-padding-x:                  1rem !default; // 1rem
$input-btn-padding-y:                  .46875rem !default; // .5rem achieve a 36dp height
//$input-btn-line-height:                1 !default; //1.25
$btn-font-weight:                400 !default; // normal
$btn-box-shadow:                 none !default;
$btn-active-box-shadow:          none !default; // inset 0 3px 5px rgba(0,0,0,.125)

//
$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $primary !default;
$btn-primary-border-color:             $btn-primary-bg !default;
//
$btn-secondary-color:            $gray-dark !default;
$btn-secondary-bg:               $body-bg !default; // #fff
$btn-secondary-border-color:           #ccc !default;
//
$btn-info-color:                 #fff !default;
$btn-info-bg:                    $info !default;
$btn-info-border-color:                $btn-info-bg !default;
//
$btn-success-color:              #fff !default;
$btn-success-bg:                 $success !default;
$btn-success-border-color:             $btn-success-bg !default;
//
$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $warning !default;
$btn-warning-border-color:             $btn-warning-bg !default;
//
$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $danger !default;
$btn-danger-border-color:              $btn-danger-bg !default;

$btn-rose-color:                 #fff !default;
$btn-rose-bg:                    $rose !default;
$btn-rose-border-color:              $btn-rose-bg !default;

$btn-default-color:                 #fff !default;
$btn-default-bg:                    $gray-color !default;
$btn-default-border-color:              $btn-default-bg !default;
//
$btn-link-disabled-color:        $gray-light !default;
//
$input-btn-padding-x-sm:               1.25rem !default;
$input-btn-padding-y-sm:               .40625rem !default; // achieve a 32dp height was .25rem

$input-btn-padding-y-lg:    1.125rem !default;
$input-btn-padding-x-lg:    2.25rem  !default;

//
//$input-btn-padding-x-lg:               1.5rem !default;
//$input-btn-padding-y-lg:               .75rem !default;
//
//// Allows for customizing button radius independently from global border radius
//$btn-border-radius:              $border-radius !default;
//$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:             .1875rem !default;
$border-radius-extreme:            2rem !default;
