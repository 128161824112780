// Bootstrap brand color customization


/*     brand Colors     */

//$primary: #4F853C !default;
$primary: #4A736B !default;
$secondary: $grey-600 !default;
$success: $green-500 !default;
$info: $cyan-500 !default;
$warning: $orange-500 !default;
$rose: $pink-500 !default;
$danger: $red-500 !default;
$light: $grey-100 !default;
$dark: $grey-800 !default;
$inverse: $black-color !default;


$theme-card-colors: () !default;
$theme-card-colors: map-merge((
  "primary": (#4A736B, #4A736B),
  "success": ($green-400, $green-600),
  "info": ($cyan-400, $cyan-600),
  "warning": ($orange-400, $orange-600),
  "danger": ($red-400, $red-600),
  "rose": ($pink-400, $pink-600)
), $theme-card-colors);

$theme-shadow-colors: () !default;
$theme-shadow-colors: map-merge((
  "primary": #4A736B,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "rose": $rose
), $theme-shadow-colors);
